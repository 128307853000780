/* @import-normalize;
@import "./fonts.scss";
@import "./colors.scss";
@import "./app.scss"; */

/* ------------------------------ RESET ------------------------------ */
html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
button,
p,
blockquote,
pre,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strong,
sub,
sup,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
figure,
figcaption,
footer,
header,
nav,
output,
section,
time,
mark,
audio,
video,
input,
textarea,
select {
    margin: 0;
    padding: 0;
    border: 0;
    /* inherits the browser's font properties: font-size 16px */
    font: inherit;
    /*  specifies the vertical alignment of an inline element */
    vertical-align: baseline;

    box-sizing: border-box;

    /*  specifies the height of line boxes within the element. */
    line-height: inherit;

    border-radius: 0;

    /* no outline around anything */
    outline: none;

    /* inherit the color value of the parent */
    color: inherit;
}

ol,
ul {
    /* no bullets in lists */
    list-style: none;
}

a {
    /* no underline */
    text-decoration: none;
}

button {
    cursor: pointer;
    background-color: transparent;
}

blockquote,
q {
    /* no quotes */
    quotes: none;
}

table {
    /* no spacing between cells*/
    border-spacing: 0;
    /*borders are collapsed - adjacent table cells share borders */
    border-collapse: collapse;
}

input,
select,
progress,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

svg {
}
/* ------------------------------------------------------------------- */

body {
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    line-height: 1;
    /* color: hsl(345, 6%, 13%); */
    color: #484848;
    -webkit-overflow-scrolling: touch;
    overflow-wrap: break-word;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
    /* This removes the drag back on chromium kiosk mode */
}

::-webkit-scrollbar {
    display: none;
    width: 0px;
}

.ReactModal__Body--open {
    overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

/* remove firefox borders */
a:focus,
a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: 0;
    outline: 0;
}

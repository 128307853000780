@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {
    --primary-color: #f0cf3e;
    --secondary-color: #f0cf3e;
    --background-color: #20485b;
    --font-color: #ffffff;
}

body {
    font-family: "Bebas Neue", sans-serif !important;
    --primary-color: #f1cf3e !important;
    background-color: #20485b !important;
    color: white !important;
}

.restaurant-list {
    display: flex;
    flex-direction: column;
    padding: 24px;
    max-width: 600px;
    width: 100%;
    margin: auto;
}

.restaurant-list-logo {
    width: 40%;
    margin: 0 auto;
    margin-bottom: 32px;
}

.restaurant-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.restaurant-name {
    font-size: 24px;
}

.button {
    color: white;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    padding: 16px 24px;
    border-radius: 3px;
    cursor: pointer;
}

.button.disabled {
    cursor: default;
    background-image: none;
    background-color: hsl(0, 0%, 70%);
}

.button.large {
    font-size: 22px;
    padding: 24px 48px;
}

/* Edits */
.button {
    color: #484848;
}
